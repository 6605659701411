
import { mapState } from 'vuex'
import { TrackingHomeClick } from '@/reporter/track'
import { TRACK_ENTRY_MAP } from '@/utils/const/apply'
export default {
  name: 'Search',
  data() {
    return {
      searchInput: '',
      site: this.$route.params.site,
      lang: this.$route.params.lang,
      trackMap: {
        waybill: TrackingHomeClick,
      },
      linkParams: { query: { scene: TRACK_ENTRY_MAP.homePage } },
      bgActive: '',
    }
  },
  computed: {
    ...mapState({
      resizeInfo: (state) => state.resizeInfo,
    }),
    isShowClearance() {
      const { site, lang } = this.$route.params
      return (
        site === 'cn' &&
        lang === 'sc' &&
        this.resizeInfo?.contentRect?.width >= 1200
      )
    },
  },
  methods: {
    // 处理点击查询跳转
    handleJumpTo() {
      this.$router.push({
        name: 'site-lang-support-querySupport-waybill',
        params: {
          site: this.$route.params.site,
          lang: this.$route.params.lang,
        },
        query: {
          No: this.searchInput,
        },
      })
    },
    track(type) {
      this.trackFn(type)
    },
    trackFn(name, properties) {
      this.trackMap[name](properties)
    },
    mouseenter() {
      this.bgActive = 'bg-active'
    },
    mouseleave() {
      this.bgActive = ''
    },
  },
}
