
import { mapState } from 'vuex'
// import { createStorage } from '@/utils/storage'

export default {
  props: {
    name: {
      type: String,
      default: 'slideInUp',
    },
    id: {
      type: String,
      default: '1',
    },
  },
  data() {
    return {
      code: 'animation-init-state',
      // className: '',
    }
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.resizeInfo?.isMobile,
    }),
    className() {
      return this.isMobile ? '' : `animate__animated animate__${this.name}`
    },
  },
  mounted() {
    // this.storage = createStorage({ storage: sessionStorage })
    // this.getState()
  },
  methods: {
    // getState() {
    //   const state = this.storage.get(`${this.code}_${this.id}`)
    //   if (!state) {
    //     this.className = this.class
    //     this.setState()
    //   }
    // },
    // setState() {
    //   this.storage.set(`${this.code}_${this.id}`, true)
    // },
  },
}
