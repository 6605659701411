import { render, staticRenderFns } from "./search.vue?vue&type=template&id=6aad5020&scoped=true&"
import script from "./search.vue?vue&type=script&lang=js&"
export * from "./search.vue?vue&type=script&lang=js&"
import style0 from "./search.vue?vue&type=style&index=0&id=6aad5020&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6aad5020",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SvgIcon: require('/app/workspace/DEPLOY/ibu-owt-core/ibu-owt-core-owt-ssr-nuxt/components/SvgIcon.vue').default,LinkTo: require('/app/workspace/DEPLOY/ibu-owt-core/ibu-owt-core-owt-ssr-nuxt/components/LinkTo.vue').default,AnimationInit: require('/app/workspace/DEPLOY/ibu-owt-core/ibu-owt-core-owt-ssr-nuxt/components/animation/init.vue').default})
